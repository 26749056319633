import './App.css';
// import Demo1 from './pages/Demo01';
// import Demo2 from './pages/Demo02';
// import Demo3 from './pages/Demo03';
// import Frame from './pages/Frame';
import Dino from './pages/Dino';

function App() {
    return (
        <div>
            {/* <Demo1/> */}
            {/*<Demo2/>*/}
            {/*<Demo3/>*/}
            {/*<Frame/>*/}
            <Dino/>
        </div>
    );
}

export default App;
