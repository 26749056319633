import React, {useEffect} from 'react';
import * as PIXI from "pixi.js";
import Stats from 'stats.js';

/**
 * dino jump
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Index(props) {
    useEffect(() => {
        // 创建应用
        const app = new PIXI.Application({
            width: window.innerWidth,
            height: window.innerHeight,
            backgroundColor: "white",
            // resolution: window.devicePixelRatio || 1,
            antialias: true, // 抗锯齿
        });
        // 将应用画布添加到 DOM 中
        document.querySelector("#content1").appendChild(app.view);
        // 创建容器
        const container = new PIXI.Container();
        // 创建云朵容器
        const cloudContainer = new PIXI.Container();
        app.stage.addChild(container, cloudContainer);
        // 添加基础纹理
        const baseTexture = PIXI.BaseTexture.from(require('../../assets/game.png'));
        // 创建恐龙纹理
        const dinoTexture = new PIXI.Texture(baseTexture, new PIXI.Rectangle(75, 0, 88, 100));
        // 创建恐龙精灵
        const dinoSprite = new PIXI.Sprite(dinoTexture);
        dinoSprite.anchor.set(0.5);
        dinoSprite.x = window.innerWidth / 2 - 120;
        dinoSprite.y = window.innerHeight / 2;
        // dinoSprite.visible = false;
        container.addChild(dinoSprite);
        // 恐龙跑步动画帧数组
        const runTextures = [];
        for (let i = 0; i < 2; i++) {
            runTextures.push(
                new PIXI.Texture(
                    baseTexture,
                    new PIXI.Rectangle(1680 + (2 + i) * 88, 0, 82, 100)
                )
            );
        }
        // 创建动画
        const runAnimation = new PIXI.AnimatedSprite(runTextures);
        runAnimation.animationSpeed = 0.1;
        runAnimation.zIndex = 3;
        runAnimation.play();
        runAnimation.visible = false;
        container.addChild(runAnimation);

        // 创建恐龙跳跃精灵
        const jumpTexture = new PIXI.Texture(
            baseTexture,
            new PIXI.Rectangle(1680, 0, 82, 100)
        );
        const jumpSprite = new PIXI.Sprite(jumpTexture);
        jumpSprite.x = 60;
        jumpSprite.y = window.innerHeight - 120;
        jumpSprite.visible = false;
        container.addChild(jumpSprite);

        // 创建地面精灵
        const groundTexture = new PIXI.Texture(
            baseTexture,
            new PIXI.Rectangle(50, 100, 2300, 30)
        );
        const groundSprite = new PIXI.TilingSprite(groundTexture); // 平铺
        groundSprite.width = window.innerWidth;
        groundSprite.height = 30;
        groundSprite.position.set(0, window.innerHeight - 50);
        groundSprite.visible = false;
        container.addChild(groundSprite);

        // 创建云朵精灵
        const cloudTexture = new PIXI.Texture(
            baseTexture,
            new PIXI.Rectangle(165, 0, 93, 30)
        );

        for (let i = 0; i < 5; i++) {
            const cloudSprite = new PIXI.Sprite(cloudTexture);
            cloudSprite.x = Math.random() * window.innerWidth - 165;
            cloudSprite.y = Math.random() * 300;
            // cloudSprite.visible = false;
            cloudContainer.addChild(cloudSprite);
        }

        // 创建仙人掌精灵
        const cactusTexture = new PIXI.Texture(
            baseTexture,
            new PIXI.Rectangle(515, 0, 30, 60)
        );
        const cactusSprite = new PIXI.Sprite(cactusTexture);
        cactusSprite.x = window.innerWidth / 2;
        cactusSprite.y = window.innerHeight - 90;
        cactusSprite.visible = false;
        container.addChild(cactusSprite);

        // 创建文字
        const startText = new PIXI.Text('开始游戏', {
            fontFamily: 'Arial',
            fontSize: 36,
            fill: 0x333333,
            align: 'center'
        });
        startText.x = window.innerWidth / 2;
        startText.y = window.innerHeight / 2;
        startText.anchor.set(0.5);
        // startText.interactive = true; // 弃用警告
        startText.eventMode = 'dynamic';
        startText.on('click', () => {
            playGame();
        });
        container.addChild(startText);

        // 游戏的状态
        let isGaming = false;
        let isGameOver = false;
        // 得分
        let score = 0;
        // 初始化跳跃的速度
        let jumpVelocity = 20;
        // 初始化重力
        let gravity = 1;

        /** 开始游戏 */
        const playGame = () => {
            isGaming = true;
            console.log('====开始游戏====');
            // 显示恐龙跑步动画
            runAnimation.visible = true;
            runAnimation.x = 60;
            runAnimation.y = window.innerHeight - 120;
            // 显示地板
            groundSprite.visible = true;
            // 显示仙人掌
            cactusSprite.visible = true;
            startText.visible = false;
            dinoSprite.visible = false;
        };

        /** 游戏结束 */
        const gameOver = () => {
            console.log('====游戏结束====');
            isGameOver = true;
            runAnimation.visible = false;
            jumpSprite.visible = true;

            // 显示得分
            const overText = new PIXI.Text('游戏结束，得分：' + score + ' 点击重新开始', {
                fontFamily: 'Arial',
                fontSize: 36,
                fill: 0x333333,
                align: 'center'
            });
            overText.x = window.innerWidth / 2;
            overText.y = window.innerHeight / 2;
            overText.anchor.set(0.5);
            overText.eventMode = 'dynamic';
            overText.on('click', () => {
                window.location.reload();
            });
            container.addChild(overText);
        }

        /** 游戏循环 */
        app.ticker.add(() => {
            if (isGameOver) {
                return;
            }

            if (isGaming) {
                // 地面移动
                groundSprite.tilePosition.x -= 10;
                // 仙人掌移动
                cactusSprite.x -= 10;
                // 云朵移动
                cloudContainer.x -= 0.2;
                // 如果仙人掌移出屏幕外，则让它重新回到屏幕
                if (cactusSprite.x < -30) {
                    cactusSprite.x = window.innerWidth;
                    score++;
                }
                if (cloudContainer.x < -window.innerWidth) {
                    cloudContainer.x = window.innerWidth;
                }
            }
            // 如果当前是在跳跃状态
            if (jumpSprite.visible) {
                jumpVelocity -= gravity;
                jumpSprite.y -= jumpVelocity;
                // 如果恐龙跳跃精灵低于地面
                if (jumpSprite.y > window.innerHeight - 120) {
                    // 则让恐龙跳跃精灵重回地面
                    jumpSprite.y = window.innerHeight - 120;
                    jumpVelocity = 20;
                    jumpSprite.visible = false;
                    runAnimation.visible = true;
                }
            }
            if (
                jumpSprite.y > cactusSprite.y - 60 &&
                cactusSprite.x < jumpSprite.x + 60 &&
                cactusSprite.x > jumpSprite.x - 60
            ) {
                // 游戏结束
                gameOver();
            }
        });

        /** 按下空格来控制恐龙的跳跃 */
        window.addEventListener('keydown', e => {
            if (e.code === 'Space' && isGaming && !jumpSprite.visible) {
                console.log('====跳跃====');
                runAnimation.visible = false;
                jumpSprite.visible = true;
                jumpVelocity = 20;
            }
        });
    }, []);

    useEffect(() => {
        const stats = new Stats();
        stats.showPanel(0);
        document.body.appendChild(stats.dom);

        function animate() {
            stats.begin();
            stats.end();
            requestAnimationFrame(animate);
        }

        requestAnimationFrame(animate);
    }, []);

    return <div id="content1"/>;
}

export default Index;